'use client';

import { createContext, useState, ReactNode, Dispatch, SetStateAction, useContext } from 'react';
import { UserData, ValidityType } from '@/lib/types';
interface DataContextType {
  data: UserData | null;
  validity: ValidityType;
  setData: Dispatch<SetStateAction<UserData | null>>;
  setValidity: Dispatch<SetStateAction<ValidityType>>;
}
export const DataContext = createContext<DataContextType | undefined>(undefined);
export function DataProvider({
  children
}: {
  children: ReactNode;
}) {
  const [data, setData] = useState<UserData | null>(null);
  const [validity, setValidity] = useState<ValidityType>({
    contactFormValid: false,
    secondTenantFormValid: false,
    paymentFormValid: false,
    summaryFormValid: false
  });
  return <DataContext.Provider value={{
    data,
    validity,
    setData,
    setValidity
  }} data-sentry-element="unknown" data-sentry-component="DataProvider" data-sentry-source-file="data-context.tsx">
      {children}
    </DataContext.Provider>;
}
export function useCid() {
  const {
    data
  } = useData();
  return data?.immutableData?.hash || null;
}
export function useData() {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
}